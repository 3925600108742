<template>
  <div class="big-data-container">
    <div class="top-search-box p-b-0 m-b-20">
      <el-form label-width="80px">
        <el-row :span="24">
          <el-col :span="4">
            <el-form-item label="公司分类">
              <el-select v-model="searchParams.tenantId" clearable size="mini" placeholder="请选择用户状态" @change="onSearch">
                <el-option v-for="role in listRoleTypes" :key="role.value" :label="role.label" :value="role.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公司名称">
              <el-input v-model="searchParams.companyName" clearable size="mini" placeholder="请输入用户名称" @change="onSearch" />
            </el-form-item>
          </el-col>
          <el-col :span="14" class="right-btns">
            <el-button class="search-box-btn" type="primary" size="mini" icon="el-icon-search" @click="onSearch">查询</el-button>
            <el-button class="search-box-btn" type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="addUserGroup">添加公司</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content-list-data-box">
      <el-table :data="dataList" style="width: 100%" :height="tableHeight"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称">
          <template slot-scope="scope">
            <el-link type="primary" :href="`/#/company/detail/${scope.row.id}`">{{ scope.row.companyName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="tenantId" label="所属分组" width="85" align="center">
          <template slot-scope="scope">
            <span>{{ roleTypes[scope.row.tenantId].label }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column prop="contact" label="联系人" width="120" align="center"></el-table-column>
        <el-table-column prop="creator" label="创建人" width="120" align="center"></el-table-column>
        <el-table-column prop="tel" label="电话" width="120"></el-table-column>
        <!-- <el-table-column label="操作"  width="120" align="center" v-if="!utils.userReadOnly(user)">
          <template slot-scope="scope">
            <div class="text-center">
              <el-button type="primary" size="mini" icon="el-icon-s-platform" @click="onDetail(scope.row)">查看详情</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import { list, del } from "@/api/business";
import businessSearch from "@/components/business/search";
import { mapGetters } from 'vuex';

let searchParams = {
  companyName: "",
  tenantId: ""
};
let pageData = {
  current: 1,
  total: 0,
  size: 20
};

const protoSearchObj = JSON.stringify(searchParams);
const protoPageData = JSON.stringify(pageData);

export default {
  components: {
    businessSearch
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      utils: $utils,
      span: 4,
      listRoleTypes: {},
      roleTypes: [].concat($statics.ROLE_GROUP_LIST),
      pageData: pageData,
      configs: [],
      dialogVisible: {
        edit: false,
        authority: false
      },
      searchParams: {},
      dataList: [],
      rowId: null,
      title: "",
      row: null
    };
  },
  created () {
    console.log("user-group");
    this.searchParams = searchParams;
    if (this.user.tenantId !== 0) {
      this.listRoleTypes = [].concat(this.roleTypes);
      this.listRoleTypes.shift();
    }
    this.getDatas();
  },
  mounted () {
    this.$route.meta.deactivated = () => {
      this.pageData = pageData = JSON.parse(protoPageData);
      this.searchParams = searchParams = JSON.parse(protoSearchObj);
    }
    this.tableHeight = document.body.scrollHeight - 220;
  },
  methods: {
    onSizeChange (e) {
      pageData.size = e;
      this.getDatas();
    },
    pageChange(e) {
      pageData.current = e;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;
        const current = pageData.current;

        const obj = Object.assign({
          page: (current - 1) * pageData.size,
          size: pageData.size,
        }, searchParams);

        if (this.user.tenantId !== 0) {
          /*
            [20240416]
            因为现在数据库 subsidiaryId 会把包含所有上级公司的 id 全部拼起来
            所以当登录用户不是超级管理员是，需要通过 检索 subsidiaryId 中含有当前登录用户的 businessid 的数据
           */
          obj.subsidiaryId = `${this.user.businessId},`;
          // 如果是独立用户，则添加创建人为自己
          if (this.user.self === 1) {
            obj.creator = this.user.username;
          }
        }

        const res = await list(obj);

        this.dataList = res.data;
        pageData.total = res.paging.total;
        this.$forceUpdate();
      } catch (e) {
        console.error(e);
        this.$message.error("读取失败");

        this.pageData = pageData = {
          current: 1,
          total: 0,
          size: pageData.size
        };
        this.dataList = [];
      } finally {
        this.loading = false;
      }
    },
    onSearch () {
      pageData.current = 1;
      this.getDatas();
    },
    onViewConfigs (row) {
      this.row = row;
      this.dialogVisible.authority = true;
      this.configs = row.configs.split(",");
    },
    editUserGroup (row) {
      this.rowId = row.id;
      this.dialogVisible.edit = true;
      this.title = "编辑公司属性";
    },
    addUserGroup () {
      this.$router.push({
        path: "/company/add"
      });
    },
    // onDetail (biz) {
    //   this.$router.push({
    //     path: `/company/detail/${biz.id}`
    //   });
    // },
    async delUserGroup (row) {
      try {
        this.loading = true;
        await del(row.id);
        this.getDatas();
      } catch {
        this.$message.error("删除失败");
      } finally {
        this.loading = false;
      }
    },
    async onCloseDialog (refName, ignore) {
			// 通过 emit 发送的关闭请求一律通过
			// 如果点击 close 图标则一律直接关闭
			if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
				// 设置 visable 后,会重新触发 close 所以需要先 remove edit
				delete this.$refs[refName];
        this.getDatas();
        this.dialogVisible[refName] = false;
			}
    }
  }
};
</script>