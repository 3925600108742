var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container" }, [
    _c(
      "div",
      { staticClass: "top-search-box p-b-0 m-b-20" },
      [
        _c(
          "el-form",
          { attrs: { "label-width": "80px" } },
          [
            _c(
              "el-row",
              { attrs: { span: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司分类" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              size: "mini",
                              placeholder: "请选择用户状态",
                            },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchParams.tenantId,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "tenantId", $$v)
                              },
                              expression: "searchParams.tenantId",
                            },
                          },
                          _vm._l(_vm.listRoleTypes, function (role) {
                            return _c("el-option", {
                              key: role.value,
                              attrs: { label: role.label, value: role.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请输入用户名称",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchParams.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "companyName", $$v)
                            },
                            expression: "searchParams.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "right-btns", attrs: { span: 14 } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "search-box-btn",
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.onSearch },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "search-box-btn",
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.addUserGroup },
                      },
                      [_vm._v("添加公司")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-list-data-box" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              height: _vm.tableHeight,
              "header-cell-style": {
                background: "#F7F7F7",
                color: "#2d2d2d",
                "text-align": "center",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "50",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.$index +
                              1 +
                              (_vm.pageData.current - 1) * _vm.pageData.size
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "companyName", label: "公司名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: `/#/company/detail/${scope.row.id}`,
                          },
                        },
                        [_vm._v(_vm._s(scope.row.companyName))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "tenantId",
                label: "所属分组",
                width: "85",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.roleTypes[scope.row.tenantId].label)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "地址" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "contact",
                label: "联系人",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "creator",
                label: "创建人",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "tel", label: "电话", width: "120" },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticClass: "text-right m-t-10",
          attrs: {
            background: "",
            layout: "sizes, prev, pager, next, jumper",
            "page-sizes": [20, 50, 100],
            "page-size": _vm.pageData.size,
            total: _vm.pageData.total,
            "current-page": _vm.pageData.current,
          },
          on: {
            "size-change": _vm.onSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }